import { defineStore } from 'pinia'

import type { User } from '../types/user.types'

type StoreState = {
  me: User | undefined
  meLoaded: boolean
  balance: number
  balanceLoaded: boolean
  counterMessages: number
}

export const useUserStore = defineStore('user', {
  state: (): StoreState => {
    return {
      me: undefined,
      meLoaded: false,
      balance: 0,
      balanceLoaded: false,
      counterMessages: 0,
    }
  },
  getters: {
    // isModerator: (state) => state.me?.roles.includes('APP_MODERATOR') || false,
  },
  actions: {},
})
